.content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem;
  border-radius: 8px;
}

.boxtitle {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
}

.title {
  font-size: 1rem;
  font-weight: bold;
  color: #fff; /* White text color */
  margin: 0;
  align-items: center;
}

.middle {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.leftsection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 1rem;
}
.rightsection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 1rem;
  max-width: 300px;
  width: 200px;
}

.label-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label-prefix,
.label-value {
  color: #fff; /* White text color */
  font-size: 0.7rem;
}

.rightsection h5 {
  color: #fff; /* White text color */
  font-size: 1rem;
  margin: 0;
  color: rgb(140, 0, 0);
  padding: 0;
}

.rightsection p {
  color: #fff; /* Light gray text color */
  font-size: 0.7rem;
  margin: 0;
}

.divider {
  width: 2px;
  height: 40px;
  max-height: 100%;
  background-color: #fff;
  color: white;
}


/* clicked content */

.wrapper {
 

}
.glassmorphism-popup {
  background: rgba(255, 255, 255, 0.1); /* Adjust opacity for a subtle glass effect */
  backdrop-filter: blur(10px); /* Adjust blur intensity for frosted glass effect */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.06);
  padding: 10px; /* Adjust padding as needed */
  max-width: 500px; /* Adjust the maximum width as needed */
}

.popup-title {
  font-size: 16px; /* Adjust font size for better visibility */
  font-weight: bold;
  color: #000;
  text-align: center;
  padding: 10px 0; /* Adjust padding as needed */
  margin: 0;
}

.popup-divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1); /* Light gray color for the divider */
  margin: 5px; /* Adjust margin as needed */
}

.popup-description {
  font-size: 14px; /* Adjust font size for better readability */
  color: #000;
}