@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.custom-chart {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.app {
  display: flex;
  position: relative;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.mapboxgl-popup-content {
  background: transparent !important;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 0 !important;
  pointer-events: auto;
  position: relative;
}



.css-blsfv0 {
  background-color: rgba(97, 97, 97, 0.92);
  border-radius: 4px;
  color: rgb(255, 255, 255);
  font-family: "Source Sans Pro", sans-serif;
  padding: 4px 8px;
  font-size: 0.589286rem;
  max-width: 600px !important;
  margin: 0px;
  overflow-wrap: break-word;
  font-weight: 500;
  position: relative;
}

.css-1p0jora-MuiTooltip-tooltip {
  background-color: rgba(97, 97, 97, 0.92);
  border-radius: 4px;
  color: #fff;
  font-family: Source Sans Pro,sans-serif;
  padding: 4px 8px;
  font-size: 0.5892857142857143rem;
  max-width: 600px !important;
  margin: 0;
  word-wrap: break-word;
  font-weight: 500;
  position: relative;
}